import React, { useContext } from "react";
import { GlobalContext } from "../context/GlobalState";
import TextField from "./Form/TextField";
import RegistrationHeader from "./RegistrationHeader";

export default function HomeContent() {

    const { setBaseInfo, baseInfo } = useContext(GlobalContext);
    const getMail = () => {
        if (baseInfo && "email" in baseInfo) {
            return baseInfo.email;
        } else {
            return ""
        }
    }
    const email = getMail();

    return (
        <div className="py-16 px-4 sm:px-6 lg:px-8 bg-white overflow-hidden text-left">
            <div className="max-w-max lg:max-w-7xl mx-auto">
                <div className="relative z-10 mb-8 md:mb-2 md:px-6">

                    <RegistrationHeader />

                    <div className="text-base max-w-prose lg:max-w-none">
                        {/* <h2 className="leading-6 text-indigo-600 font-semibold tracking-wide uppercase">Schliesse deine Registrierung ab und sichere dir deine Traumimmobilie</h2> */}

                        <h2 className="mt-2 text-3xl leading-8 font-extrabold tracking-tight text-theme_blue border-b-2 border-theme_blue border-x-none border-t-none pb-8">
                            Schliesse deine Registrierung ab und sichere dir deine Traumimmobilie
                        </h2>
                    </div>
                </div>
                <div className="relative">

                    <div className="relative md:bg-white md:p-6">
                        <div className="lg:grid lg:grid-cols-2 lg:gap-6">
                            <div className="prose prose-indigo prose-lg text-gray-500 lg:max-w-none ">
                                <TextField
                                    id="name"
                                    label="Name"
                                    type="text"
                                    placeholder="Mustermann"
                                    autoFocus={true}
                                />
                                <TextField
                                    id="adresse"
                                    label="Adresse"
                                    type="text"
                                    placeholder="Dorfstrasse 42"
                                />
                                <TextField
                                    id="telefon"
                                    label="Telefon"
                                    type="tel"
                                    placeholder="XXX XXX XX XX"
                                />
                                <TextField
                                    id="geburtsdatum"
                                    label="Geburtsdatum"
                                    type="date"
                                    placeholder="XX.XX.XXXX"
                                />
                            </div>
                            <div className="mt-6 text-gray-500 lg:mt-0">
                                <TextField
                                    id="vorname"
                                    label="Vorname"
                                    type="text"
                                    placeholder="Hans"
                                />
                                <TextField
                                    id="plz"
                                    label="PLZ"
                                    type="text"
                                    placeholder="XXXX"
                                />
                                <TextField
                                    id="ort"
                                    label="Ort"
                                    type="text"
                                    placeholder="Luzern"
                                />
                                <TextField
                                    id="email"
                                    label="E-Mail"
                                    type="email"
                                    placeholder="hans@mustermann.ch"
                                    value={email}
                                />
                            </div>
                        </div>

                        <div className="mt-24 flex justify-center">
                            <a
                                href="#"
                                className="flex items-center justify-center px-5 py-3 border border-transparent text-base font-medium text-white bg-theme_green hover:bg-theme_blue transition-colors font-bold"
                            >
                                Registrierung abschliessen
                            </a>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    )
}