import React from "react";
import SortIndication from "./SortIndicator";
import TableLink from "./TableLink";
import { SearchIcon } from '@heroicons/react/solid'
import { ChevronDownIcon } from "@heroicons/react/outline";



export default function BaseTable({
    filter = false,
    headers = [
        {
            label: "Objekttyp",
            className: "py-1.5 pl-4 pr-3 text-left text-md font-normal text-gray-900 sm:pl-6 md:pl-0",
        }, {
            label: "Strasse",
            className: "py-1.5 px-3 text-left text-md font-normal text-gray-900",
        },
        {
            label: "Ort",
            className: "py-1.5 px-3 text-left text-md font-normal text-gray-900",
        },
        {
            label: "Status",
            className: "py-1.5 px-3 text-left text-md font-normal text-gray-900",
        },

    ],
    data = [

    ]
}) {
    return (
        <table className="relative min-w-full divide-theme_blue divide-y-2 table-fixed">

            <thead>
                {filter &&
                    <tr className=""
                    >
                        <td
                            className="whitespace-nowrap pl-4 pr-3 text-md font-bold text-gray-900 sm:pl-6 md:pl-0"
                            colSpan={5}
                        >
                            <div className="flex-1 flex justify-center lg:justify-end border-theme_blue rounded-full border-1 focus-theme_blue">
                                <div className="w-full">
                                    <label htmlFor="search" className="sr-only">
                                        Suche
                                    </label>
                                    <div className="relative">
                                        <div className="absolute inset-y-0 right-0 pr-1 flex items-center pointer-events-none">
                                            <SearchIcon className="h-8 w-8 p-2 text-white bg-theme_blue rounded-full" aria-hidden="true" />
                                        </div>
                                        <input
                                            id="search"
                                            name="search"
                                            className="block w-full pr-10 pl-3 py-2 rounded-full leading-5 focus:outline-none sm:text-sm text-theme_blue"
                                            placeholder="Suche..."
                                            type="search"
                                        />
                                    </div>
                                </div>
                            </div>
                        </td>
                        <td
                            className="whitespace-nowrap py-4 px-3 text-md text-gray-500"
                        >
                            <div className="flex-1 flex justify-center lg:justify-end  rounded-full border-1">
                                <div className="w-full">
                                    <label htmlFor="search" className="sr-only">
                                        Suche
                                    </label>
                                    <div className="relative">
                                        <div className="absolute inset-y-0 right-0 pr-1 flex items-center pointer-events-none">
                                            <ChevronDownIcon className="h-8 w-8 p-2 text-white bg-theme_blue rounded-full" aria-hidden="true" />
                                        </div>
                                        <input
                                            id="search"
                                            name="search"
                                            className="block w-full pr-10 pl-3 py-2 rounded-full leading-5 focus:outline-none sm:text-sm text-theme_blue border-theme_green"
                                            value="1.1.2022 - 31.12.2022"
                                            type="text"
                                        />
                                    </div>
                                </div>
                            </div>
                        </td>
                        <td
                            className="whitespace-nowrap py-4 px-3 text-md text-gray-500"
                        >
                            <div className="flex-1 flex justify-center lg:justify-end border-theme_blue rounded-full border-1">
                                <div className="w-full">
                                    <label htmlFor="search" className="sr-only">
                                        Suche
                                    </label>
                                    <div className="relative">
                                        <div className="absolute inset-y-0 right-0 pr-1 flex items-center pointer-events-none ">
                                            <ChevronDownIcon className="h-8 w-8 p-2 text-white bg-theme_blue rounded-full" aria-hidden="true" />
                                        </div>
                                        <input
                                            id="search"
                                            name="search"
                                            className="block w-full pr-10 pl-3 py-2 rounded-full leading-5 focus:outline-none sm:text-sm text-theme_blue border-theme_green"
                                            placeholder="Status: Alle"
                                            value="Status: Alle"
                                            type="text"
                                        />
                                    </div>
                                </div>
                            </div>
                        </td>
                    </tr>
                }
                <tr
                    className="border-t-2 border-theme_blue"
                >
                    {headers.map((header, index) => (
                        <th
                            scope="col"
                            key={`${index}`}
                            className={`${header.className}`}
                        >
                            <div
                                className="flex"
                            >
                                {header.label}
                                {filter &&
                                    <SortIndication />
                                }
                            </div>
                        </th>
                    ))}
                </tr>
            </thead>
            <tbody className="divide-y divide-theme_blue">
                {data.map((dataRow, index) => {
                    // console.log("children", col.children)
                    const inZuteilung = dataRow.cols[6].children.props.status === 0;
                    return (
                        <tr
                            key={`${index}`}
                            className={`hover:bg-gray-50 focus-within:bg-gray-100 cursor-pointer ${inZuteilung ? "bg-theme_orange/[.2]" : ""}`}
                            onClick={() => {
                                window.location.href = '/projekt';
                            }}
                        >
                            {dataRow.cols.map((col, index) => (
                                <td
                                    key={`${index}`}
                                    className={`${col.className}`}
                                >
                                    {col.children}
                                </td>
                            ))}
                        </tr>
                    )
                }
                )}
            </tbody>
        </table>
    )
}