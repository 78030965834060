import React, { useState } from 'react';

import TopBar from '../components/TopBar';
import HomeContent from '../components/HomeContent';
import Header from '../components/Header';
import Base from '../layout/base';
import { CheckCircleIcon, PencilIcon } from '@heroicons/react/outline';
import TextField, { FieldDummy } from '../components/Form/TextField';
import DocField from '../components/Form/DocField';
import { getIcon } from '../components/StatusIcons';
import BaseTable from '../components/Table/BaseTable';
import AdressFormular, { AdressFormularDummy } from '../components/Form/AdressFormular';
import Select from '../components/Form/Select';
import Notification from '../components/Notification';

const projekte = [
    {
        objektTyp: "Haus",
        strasse: "Musterstrasse 42",
        plzOrt: "6005 Luzern",
        angelegt: true,
        eingereicht: true,
        inPruefung: true,
        freigabe: true,
        status: "unterzeichnet"
    },
]

function Projekt() {

    const [saved, setSaved] = useState(false);

    const formState = {
        name: "Mustermann",
        strasse: "Musterstrasse",
        nummer: 42,
        zivilstand: "ledig",
        telefon: "041 910 30 50",
        geburtsdatum: "01.01.1970",
        vorname: "Max",
        plz: 6003,
        ort: "Luzern",
        email: "max@mustermann.ch",
    }

    const projekteRows = projekte.map((p, index) => {
        return {
            cols: [
                {
                    className: "whitespace-nowrap py-4 pl-4 pr-3 text-md font-bold text-gray-900 sm:pl-6 md:pl-0 w-[10%]",
                    children: (
                        <span>
                            {p.objektTyp}
                        </span>
                    )
                },
                {
                    className: "whitespace-nowrap py-4 px-3 text-md text-gray-500 w-[15%]",
                    children: (
                        <span>
                            {p.strasse}
                        </span>
                    )
                },
                {
                    className: "whitespace-nowrap py-4 px-3 text-md text-gray-500  w-[35%]",
                    children: (
                        <span>
                            {p.plzOrt}
                        </span>
                    )
                },
                {
                    className: "whitespace-nowrap py-4 px-3 text-md text-gray-500 w-[10%]",
                    children: (
                        <div className="flex flex-row gap-2 items-center">
                            {getIcon(p.angelegt)}
                            {getIcon(p.eingereicht)}
                            {getIcon(p.inPruefung)}
                            {getIcon(p.freigabe)}
                            {p.status}
                        </div>)
                }
            ]
        }
    });

    return (
        <Base>
            <Notification
                show={saved}
                setShow={setSaved}
            />
            <div className="App">
                <TopBar />
                <div className="py-16 max-w-7xl mx-auto px-2 sm:px-6 lg:px-8 bg-white overflow-hidden text-left">
                    <div className="relative z-10 mb-8 md:mb-2 md:px-6"></div>
                    <Header title="Antragsstellende Person 1" />

                    <div className="bg-white overflow-hidden text-left">

                        <div className="relative">

                            <AdressFormularDummy
                                handleInputChange={() => { }}
                                formState={formState}
                            />

                        </div>
                    </div>



                    <div className="pb-8 bg-white overflow-hidden text-left">


                        <div className="pt-24 bg-white overflow-hidden text-left">
                            <div className="max-w-max lg:max-w-7xl mx-auto">
                                <div className="relative z-10 mb-8 md:mb-2 md:px-6"></div>

                                <div className="text-base max-w-prose lg:max-w-none">

                                    <h2 className="mt-2 text-3xl leading-8 font-extrabold tracking-tight text-theme_blue border-b-2 border-theme_blue border-x-none border-t-none pb-2">
                                        Angaben zur Immobilie
                                    </h2>
                                </div>
                            </div>


                            <div className="relative">
                                <div className="relative md:bg-white pb-8">
                                    <div className="lg:grid lg:grid-cols-2 lg:gap-6">
                                        <div className="prose prose-indigo prose-lg text-gray-500 lg:max-w-none ">
                                            <FieldDummy
                                                id="adresse"
                                                label="Strasse"
                                                type="text"
                                                placeholder="Dorfstrasse"
                                            />
                                            <FieldDummy
                                                id="plz"
                                                label="PLZ"
                                                type="text"
                                                value="6005"
                                            />
                                            <FieldDummy
                                                id="art"
                                                label="Objektart"
                                                type="text"
                                                value={"Wohnung"}
                                            />
                                        </div>
                                        <div className="prose prose-indigo prose-lg text-gray-500 lg:max-w-none">
                                            <FieldDummy
                                                id="nummer"
                                                label="Nummer"
                                                type="text"
                                                placeholder="42"
                                            />
                                            <FieldDummy
                                                id="ort"
                                                label="Ort"
                                                type="text"
                                                value="Luzern"
                                            />
                                            <FieldDummy
                                                id="name"
                                                label="Kaufpreis"
                                                type="text"
                                                value={"CHF 1'050'000"}
                                            />
                                        </div>
                                    </div>
                                </div>

                            </div>
                        </div>




                        <div className="pb-8 bg-white overflow-hidden text-left">
                            <div className="max-w-max lg:max-w-7xl mx-auto">

                                <div className="text-base max-w-prose lg:max-w-none">

                                    <h2 className="mt-2 text-2xl leading-8 font-extrabold tracking-tight text-theme_blue border-b-2 border-theme_blue border-x-none border-t-none pb-2">
                                        Dokumente
                                    </h2>
                                </div>
                            </div>
                            <div className="relative">

                                <div className="relative md:bg-white pb-8">
                                    <div className="lg:grid lg:grid-cols-2 lg:gap-6">
                                        <div className="prose prose-indigo prose-lg text-gray-500 lg:max-w-none ">
                                            <DocField
                                                id="name"
                                                label="Situations- / Katasterplan"
                                                type="text"
                                                placeholder="Mustermann"
                                                autoFocus={true}
                                            />
                                            <DocField
                                                id="adresse"
                                                label="Gebäudeversicherungsnachweis"
                                                type="text"
                                                placeholder="Dorfstrasse 42"
                                            />
                                            <DocField
                                                id="telefon"
                                                label="Verkaufsdokumentation"
                                                type="tel"
                                                placeholder="XXX XXX XX XX"
                                            />
                                            <DocField
                                                id="vorname"
                                                label="Letztjährige Betriebs- und Nebenkostenabrechnung"
                                                type="text"
                                                placeholder="Hans"
                                            />
                                        </div>
                                        <div className="prose prose-indigo prose-lg text-gray-500 lg:max-w-none ">
                                            <DocField
                                                id="vorname"
                                                label="Plan / Grundriss des Gebäudes"
                                                type="text"
                                                placeholder="Hans"
                                            />
                                            <DocField
                                                id="plz"
                                                label="Fotos der Liegenschaft innen und aussen"
                                                type="text"
                                                placeholder="XXXX"
                                            />
                                            <DocField
                                                id="ort"
                                                label="Stockwerkeigentumsreglement"
                                                type="text"
                                                placeholder="Luzern"
                                            />
                                            <DocField
                                                id="ort"
                                                label="Plan der Stockwerkeinheit mit Nettofläche in m&sup2;"
                                                type="text"
                                                placeholder="Luzern"
                                            />
                                        </div>
                                    </div>
                                </div>
                            </div>

                        </div>

                        <div className="pt-24 bg-white overflow-hidden text-left">
                            <div className="max-w-max lg:max-w-7xl mx-auto">
                                <div className="relative z-10 mb-8 md:mb-2 md:px-6"></div>

                                <div className="text-base max-w-prose lg:max-w-none">

                                    <h2 className="mt-2 mb-6 text-3xl leading-8 font-extrabold tracking-tight text-theme_blue border-b-2 border-theme_blue border-x-none border-t-none pb-2">
                                        Versicherungsdokumente
                                    </h2>
                                </div>
                            </div>
                        </div>




                        <div className="pb-8 bg-white overflow-hidden text-left">
                            <div className="relative">

                                <div className="relative md:bg-white pb-8">
                                    <div className="lg:grid lg:grid-cols-2 lg:gap-6">
                                        <div className="prose prose-indigo prose-lg text-gray-500 lg:max-w-none ">
                                            <Select
                                                id={"zuteilung"}
                                                label={"Zuteilung"}
                                                options={[
                                                    "Lothar Arnold",
                                                    "Simone Grundig",
                                                    "Hans Fehr"
                                                ]}
                                            />
                                            <DocField
                                                id="name"
                                                label="Versicherungsofferte"
                                                type="text"
                                                placeholder="Mustermann"
                                                autoFocus={true}
                                            />
                                            <DocField
                                                id="adresse"
                                                label="Versicherungspolice"
                                                type="text"
                                                placeholder="Dorfstrasse 42"
                                            />
                                        </div>
                                        <div className="prose prose-indigo prose-lg text-gray-500 lg:max-w-none ">
                                            <DocField
                                                id="vorname"
                                                label="Unterzeichnete Versicherungsofferte"
                                                type="text"
                                                placeholder="Hans"
                                            />
                                            <DocField
                                                id="vorname"
                                                label="Ergänzende Dokumente"
                                                type="text"
                                                placeholder="Hans"
                                            />

                                        </div>
                                    </div>
                                </div>


                                <div className="relative pb-3 mb-2">
                                    <label htmlFor={"bemerkungen"} className="block text-md font-bold text-gray-700 text-lg whitespace-nowrap mb-2">
                                        Bemerkungen:
                                    </label>
                                    <div className="relative mt-1 justify-end flex">

                                        <textarea
                                            className='w-full h-[200px] border border-theme_blue'
                                        >

                                        </textarea>
                                    </div>
                                </div>

                                <button
                                    className='bg-theme_green hover:bg-theme_blue active:translate-y-0.5 text-white py-2 px-8 text-lg font-bold'
                                    onClick={() => {
                                        setSaved(true);
                                    }}
                                >
                                    Speichern
                                </button>

                            </div>

                        </div>



                    </div>


                </div>
            </div>
        </Base >
    );
}

export default Projekt;
