import React from "react"
import { FieldDummy } from "./TextField"


export default function Select({
    id,
    label,
    type,
    placeholder,
    autoFocus = false,
    value,
    className = "focus:ring-theme_blue focus:border-theme_blue block w-full border-gray-300 text-md text-gray-700",
    tabIndex = undefined,
    onChange = () => { },
    options = [
        "bitte auswählen...",
        "ledig",
        "verheiratet",
        "in Partnerschaft",
        "geschieden",
        "verwitwet",
    ]
}) {
    return (
        <div className="relative pb-2 mb-2 flex justify-between text-md items-center border-b-2 border-theme_blue border-x-none border-t-none">
            <label htmlFor={id} className="block text-md text-gray-700 font-bold">
                {label}:
            </label>
            <div className="mt-1 w-[70%]">
                <div className="mt-1 sm:mt-0 w-full relative">
                    <select
                        id={id}
                        tabIndex={7}
                        name={id}
                        className="w-full block focus:ring-theme_blue focus:border-theme_blue border-gray-300"
                        onChange={e => {
                            onChange(e)
                        }}
                        value={value}
                    >
                        {options.map((option, index) => {
                            return (
                                <option key={index} value={option}>{option}</option>
                            )
                        })}
                    </select>
                </div>
            </div>
        </div>
    )
}


export function SelectDummy({
    id,
    label,
    type,
    placeholder,
    autoFocus = false,
    value,
    className = "focus:ring-theme_blue focus:border-theme_blue block w-full border-transparent appearance-none text-md text-gray-700",
    tabIndex = undefined,
    onChange = () => { },
    options = [
        "bitte auswählen...",
        "ledig",
        "verheiratet",
        "in Partnerschaft",
        "geschieden",
        "verwitwet",
    ]
}) {
    return (
        <FieldDummy
            id={id}
            key={id}
            label={label}
            type="text"
            value={value}
            // placeholder="Mustermann"
            autoFocus={autoFocus}
            tabIndex={tabIndex}
            onChange={() => {}}
        />
    )
}