import { DocumentAddIcon, DocumentIcon } from "@heroicons/react/outline"
import React from "react"


export default function DocField({
    id,
    label,
    type,
    placeholder,
    autoFocus = false,
    value,
    className = "block w-full text-md border-0"
}) {
    return (
        <div className="relative py-3 mb-2 flex justify-between items-center border-b-2 border-theme_blue border-x-none border-t-none">
            <label htmlFor={id} className="block text-md text-gray-700 whitespace-nowrap">
                {label}
            </label>
            <div className="relative mt-1 justify-end flex w-[70%]">
              
                <DocumentAddIcon
                    className="relative right-0 top-0 bottom-0 flex justify-self-center w-6 h-6 text-theme_blue"
                />
            </div>
        </div>
    )
}