import React from "react"


export default function TextField({
    id,
    label,
    type,
    placeholder,
    autoFocus = false,
    value,
    className = "focus:ring-theme_blue focus:border-theme_blue block w-full border-gray-300 text-md text-gray-700",
    tabIndex = undefined,
    onChange = () => { }
}) {
    return (
        <div className="relative pb-2 mb-2 flex justify-between text-md items-center border-b-2 border-theme_blue border-x-none border-t-none">
            <label htmlFor={id} className="block text-md text-gray-700 font-bold">
                {label}:
            </label>
            <div className="mt-1 w-[70%]">
                <input
                    type={type}
                    name={id}
                    id={id}
                    className={className}
                    placeholder={placeholder}
                    autoFocus={autoFocus}
                    value={value}
                    onChange={onChange}
                    tabIndex={tabIndex}
                />
            </div>
        </div>
    )
}

export function FieldDummy({
    id,
    label,
    type,
    placeholder,
    autoFocus = false,
    value,
    className = "focus:ring-theme_blue focus:border-theme_blue block w-full border-transparent text-md text-gray-700",
    tabIndex,
    onChange
}) {
    return (
        <div
            className="relative pb-2 mb-2 flex justify-between text-md items-center border-b-2 border-theme_blue border-x-none border-t-none"
            key={id}
        >
            <label htmlFor={id} className="block text-md text-gray-700 font-bold">
                {label}:
            </label>
            <div className="mt-1 w-[70%]">
                <input
                    type={type}
                    name={id}
                    id={id}
                    className={className}
                    placeholder={placeholder}
                    autoFocus={autoFocus}
                    value={value}
                    onChange={onChange}
                    tabIndex={tabIndex}
                />
            </div>
        </div>
    )
}