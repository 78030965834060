import React from "react";

export const getIcon = (
    flag,
    title
) => {
    if (flag) {
        return (
            <span
                className="relative w-5 h-5 flex items-center justify-center bg-white border-2 border-theme_green rounded-full"
                aria-current="step"
            // title={title}
            >
                <span className="h-5 w-5 bg-theme_green rounded-full" aria-hidden="true"></span>
            </span>
        )

    } else {
        return (
            <span
                className="relative w-5 h-5 flex items-center justify-center bg-white border-2 border-theme_green rounded-full"
                aria-current="step"
            // title={title}
            >
            </span>
        )

    }
}