import React from "react";
import logo from "../images/getyourhouse.svg";
import Progress from "./Form/Progress";

export default function RegistrationHeader() {
    return (
        <div className="bg-white overflow-hidden border-b-2 border-theme_blue border-x-none border-t-none mb-20">
            <div className="grid grid-cols-1 divide-y divide-transparent  sm:grid-cols-3 sm:divide-y-0 sm:divide-x items-center">
                <div key={"title"} className="py-5 text-sm font-medium text-center flex justify-start">
                    <h2 className="mt-2 text-3xl leading-8 font-extrabold tracking-tight text-theme_blue">
                        Registration
                    </h2>
                </div>
                <div key={"progress"} className="px-6 py-5 text-sm font-medium text-center flex gap-8 justify-center">
                    <Progress />

                    {/* {[
                        "!border-theme_blue !text-theme_blue",
                        "",
                        "",
                        "",
                    ].map((style, index) => {
                        return (
                            <div key={index} className={`rounded-full border-2 items-center justify-center flex text-theme_green border-theme_green h-8 w-8 ${style}`}>
                                {index + 1}
                            </div>
                        )
                    })} */}
                </div>
                <div key={"logo"} className="py-5 text-sm font-medium text-center flex justify-end">
                    <img
                        className="w-[160px] h-auto"
                        src={logo}
                    />
                </div>
            </div>
        </div>
    )
}