import React from "react";
import logo from "../images/getyourhouse.svg";
import Progress from "./Form/Progress";

export default function Header({
    title
}) {
    return (
        <div className="bg-white overflow-hidden border-b-2 border-theme_blue border-x-none border-t-none mb-8">
            <div className="grid grid-cols-1 divide-y divide-transparent  sm:grid-cols-2 sm:divide-y-0 sm:divide-x items-center">
                <div key={"title"} className="py-5 text-sm font-medium text-center flex justify-start">
                    <h2 className="mt-2 text-3xl leading-8 font-extrabold tracking-tight text-theme_blue">
                        {title}
                    </h2>
                </div>
                <div key={"logo"} className="py-0 text-sm font-medium text-center flex justify-end">
                    <img
                        className="w-[160px] h-auto"
                        src={logo}
                    />
                </div>
            </div>
        </div>
    )
}