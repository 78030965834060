import { ChevronDownIcon, ChevronUpIcon } from "@heroicons/react/outline";
import React from "react";


export default function SortIndication({

}) {
    return (
        <div
            className="ml-2 h-[20px] overflow-hidden"
        >
            <ChevronUpIcon
                className="h-4 w-4 text-gray-500"
            />
            <ChevronDownIcon
                className="h-4 w-4 -mt-[7px] text-gray-500"
            />
        </div>
    )
}