import React from "react";
import Select, { SelectDummy } from "./Select";
import TextField, { FieldDummy } from "./TextField";

const fieldsLeft = [
    {
        id: "name",
        label: "Name",
        type: "text",
        autoFocus: true,
        tabIndex: 1,
    },
    {
        id: "strasse",
        label: "Strasse",
        type: "text",
        autoFocus: false,
        tabIndex: 3,
    },
    {
        id: "plz",
        label: "PLZ",
        type: "text",
        autoFocus: false,
        tabIndex: 5,
    },
    {
        id: "zivilstand",
        label: "Zivilstand",
        type: "checkbox",
        autoFocus: false,
        options: [
            "bitte auswählen...",
            "ledig",
            "verheiratet",
            "verwitwet",
            "in Partnerschaft",
            "sonstiges",
        ],
        tabIndex: 7,
    },
    {
        id: "telefon",
        label: "Telefon",
        type: "text",
        autoFocus: false,
        tabIndex: 9,
    }
]

const fieldsRight = [
    {
        id: "vorname",
        label: "Vorname",
        type: "text",
        autoFocus: false,
        tabIndex: 2,
    },
    {
        id: "nummer",
        label: "Nummer",
        type: "text",
        autoFocus: false,
        tabIndex: 4,
    },
    {
        id: "ort",
        label: "Ort",
        type: "text",
        autoFocus: false,
        tabIndex: 6,
    },
    {
        id: "geburtsdatum",
        label: "Geburtsdatum",
        type: "text",
        autoFocus: false,
        tabIndex: 8,
    },
    {
        id: "email",
        label: "E-Mail",
        type: "text",
        autoFocus: false,
        tabIndex: 10
    }
]


export default function AdressFormular({
    handleInputChange,
    formState,
    options = [fieldsLeft, fieldsRight]
}) {

    const mapToField = ({
        id,
        label,
        type,
        autoFocus,
        options,
        tabIndex
    }, index) => {
        if (type === "checkbox") {
            return (
                <Select
                    id={id}
                    label={label}
                    value={formState?.[id]}
                    onChange={handleInputChange}
                    options={options}
                />
            )
        } else {
            return (
                <TextField
                    id={id}
                    label={label}
                    type={type}
                    value={formState?.[id]}
                    // placeholder="Mustermann"
                    autoFocus={autoFocus}
                    tabIndex={tabIndex}
                    onChange={handleInputChange}
                />
            )
        }

    }



    return (
        <div className="lg:grid lg:grid-cols-2 lg:gap-6">
            <div className="prose prose-indigo prose-lg text-gray-500 lg:max-w-none ">
                {options[0].map(mapToField)}
            </div>
            <div className="mt-6 text-gray-500 lg:mt-0">
                {options[1].map(mapToField)}
            </div>
        </div>
    )
}


export function AdressFormularDummy({
    handleInputChange,
    formState,
    options = [fieldsLeft, fieldsRight]
}) {

    const mapToField = ({
        id,
        label,
        type,
        autoFocus,
        options,
        tabIndex
    }, index) => {
        if (type === "checkbox") {
            return (
                <SelectDummy
                    key={id}
                    id={id}
                    label={label}
                    value={formState?.[id]}
                    onChange={handleInputChange}
                    options={options}
                />
            )
        } else {
            return (
                <FieldDummy
                    key={id}
                    id={id}
                    label={label}
                    type={type}
                    value={formState?.[id]}
                    // placeholder="Mustermann"
                    autoFocus={false}
                    tabIndex={tabIndex}
                    onChange={handleInputChange}
                />
            )
        }

    }



    return (
        <div id="dummy" className="lg:grid lg:grid-cols-2 lg:gap-6">
            <div className="prose prose-indigo prose-lg text-gray-500 lg:max-w-none ">
                {options[0].map(mapToField)}
            </div>
            <div className="mt-6 text-gray-500 lg:mt-0">
                {options[1].map(mapToField)}
            </div>
        </div>
    )
}